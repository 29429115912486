import { ChangeEvent, createRef, KeyboardEvent, useEffect, useState } from 'react';

import { sleep, stringToNumber } from '@robotrader/common-utils';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components/macro';

import { Block, Button, Flex, Input, Link, RouterLink, Table, Text } from '@/atoms';
import { useMediaQuery } from '@/hooks';
import { Icon } from '@/molecules';

import Colors from '../Colors';

type StringOrFunctionString<T> = ((a: T) => string | undefined) | string;
type StringOrJSXStringFunction<T> = string | ((a: T) => string | JSX.Element | undefined);
type AlignProp = 'left' | 'right' | 'center' | 'justify';

// type TableObjectActionsProperties<T> = {
//   data: StringOrJSXStringFunction<T>;
//   onClick: (a: T) => void | Promise<void>;
//   visible?: ((a?: T) => boolean) | (() => boolean) | boolean;
//   color?: StringOrFunctionString<T>;
// };

export interface TableObjectProperties<T> {
  label: string;
  align?: AlignProp;
  data: StringOrJSXStringFunction<T>;
  visible?: ((a?: T) => boolean) | (() => boolean) | boolean;
  color?: StringOrFunctionString<T>;
  onClick?: (a: T) => void | Promise<void>;
  link?: (a: T) => string;
  routerLink?: (a: T) => string;
  minWidth?: StringOrFunctionString<T>;
  // actions?: TableObjectActionsProperties<T>[];
}

interface DynamicTableTDProps {
  prop: TableObjectProperties<any>;
  element: any;
  align: AlignProp;
}

const DEFAULT_ALIGN: AlignProp = 'left';

const DynamicTableTD = (props: DynamicTableTDProps) => {
  const { prop, element, align } = props;
  const onClick = 'onClick' in prop ? prop.onClick : undefined;
  // const actions = 'actions' in prop ? prop.actions : undefined;
  const link = 'link' in prop ? prop.link : undefined;
  const routerLink = 'routerLink' in prop ? prop.routerLink : undefined;
  const color = typeof prop.color === 'function' ? prop.color(element) : prop.color;
  const minWidth = typeof prop.minWidth === 'function' ? prop.minWidth(element) : prop.minWidth;

  const getData = (d: StringOrJSXStringFunction<any>, obj: any) =>
    typeof d === 'function' ? d(obj) : obj[d];

  return (
    <Table.TD
      style={{
        textAlign: align,
        color,
        minWidth,
      }}
    >
      {onClick && getData(prop.data, element) !== undefined && (
        <Button
          $backgroundColor={color}
          $backgroundColorHover={color}
          $size="sm"
          onClick={($event) => {
            $event.stopPropagation();
            onClick(element);
          }}
        >
          {getData(prop.data, element)}
        </Button>
      )}
      {routerLink && (
        <RouterLink $transparent $underline to={`${routerLink(element)}`}>
          {getData(prop.data, element)}
        </RouterLink>
      )}
      {link && (
        <Link $transparent $underline href={`${link(element)}`}>
          {getData(prop.data, element)}
        </Link>
      )}
      {!onClick && !link && !routerLink && getData(prop.data, element)}
    </Table.TD>
  );
};

const ALLOWED_ITEMS_PER_PAGE = [10, 20, 30, 50, 100] as const;

interface DynamicTableProps {
  elements: Array<any>;
  properties: Array<TableObjectProperties<any>>;
  uniqueKeyName: string;
  itemsPerPage?: typeof ALLOWED_ITEMS_PER_PAGE[number];
  showIdColumn?: boolean;
  fallbackText?: string;
  pagination?: boolean;
  exportable?: boolean;
  searchable?: boolean;
  onRowSelected?: (v: any) => void;
}

const DynamicTable = (props: DynamicTableProps) => {
  const {
    elements,
    properties,
    showIdColumn,
    uniqueKeyName,
    fallbackText,
    onRowSelected,
    itemsPerPage: itemsPerPageProps,
    exportable = false,
    pagination = true,
    searchable = true,
  } = props;
  const [filteredElements, setFilteredElements] = useState<Array<any>>(elements);
  const [totalElements, setTotalElements] = useState<number>(filteredElements.length);
  const ITEMS_PER_PAGE = [...ALLOWED_ITEMS_PER_PAGE, totalElements];
  const [offset, setOffset] = useState<number>(pagination ? 0 : totalElements);
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    pagination ? itemsPerPageProps || ITEMS_PER_PAGE[2] : totalElements,
  );
  const [search, setSearch] = useState<string>('');
  const mediaQueryMatches = useMediaQuery('(min-width: 600px)');
  const tableRef = createRef<HTMLTableElement>();
  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    copyStyles: true,
  });

  useEffect(() => {
    setOffset(0);
  }, [itemsPerPage, elements]);

  useEffect(() => {
    setTotalElements(filteredElements.length);
  }, [filteredElements]);

  useEffect(() => {
    let fElements: Array<any> = elements;

    if (search && search !== '') {
      fElements = elements.filter((element) => {
        const elementPropertiesName = Object.getOwnPropertyNames(element);

        return elementPropertiesName.some((p) => {
          const value = element[p];
          const stringValue = value ? JSON.stringify(value).toLowerCase() : undefined;

          return stringValue && stringValue.includes(search.toLowerCase());
        });
      });
    }

    setFilteredElements(fElements);
  }, [search, elements]);

  const isPropertyVisible = (p: TableObjectProperties<any>, element?: any) => {
    if (p.visible === undefined) return true;

    const isVisible = typeof p.visible === 'function' ? p.visible(element) : p.visible;

    return isVisible;
  };

  const getNextOffset = () => {
    const nextOffset = offset + itemsPerPage;

    return nextOffset > totalElements ? totalElements : nextOffset;
  };

  const handleFirst = () => {
    setOffset(0);
  };

  const handleLast = () => {
    let lastOffset = totalElements - itemsPerPage;

    if (lastOffset < 0) lastOffset = 0;

    setOffset(lastOffset);
  };

  const handlePrev = () => {
    if (offset <= 0) return;

    let newOffset = offset - itemsPerPage;

    if (newOffset < 0) newOffset = 0;

    setOffset(newOffset);
  };

  const handleNext = () => {
    const nextOffset = getNextOffset();

    if (nextOffset >= totalElements) return;

    setOffset(nextOffset);
  };

  if (elements.length === 0) {
    return (
      <Block style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Text.Span>{fallbackText || 'There are no items to show.'}</Text.Span>
      </Block>
    );
  }

  return (
    <>
      <Flex.Container
        style={{ marginBottom: '1rem' }}
        flexDirection={mediaQueryMatches ? 'row' : 'column'}
        // justifyContent={
        //   mediaQueryMatches && searchable && exportable ? 'space-between' : 'flex-end'
        // }
        justifyContent="flex-end"
        alignItems={mediaQueryMatches ? 'center' : undefined}
      >
        {searchable && (
          <Input.Search
            placeholder="Search"
            style={{
              minWidth: '200px',
              width: mediaQueryMatches ? '300px' : '100%',
              marginRight: mediaQueryMatches ? 'auto' : '0',
            }}
            onKeyDown={async (event: KeyboardEvent<HTMLInputElement>) => {
              if (
                event.key !== 'Enter' &&
                event.key !== 'Clear' &&
                event.key !== 'Backspace' &&
                event.key !== 'Paste'
              )
                return;

              const { currentTarget } = event;

              await sleep(100);

              const searchValue: string = currentTarget.value;

              setSearch(searchValue);
            }}
          />
        )}
        {pagination && (
          <Flex.Container
            style={{
              marginLeft: mediaQueryMatches ? '1rem' : '0',
              marginTop: mediaQueryMatches ? '0' : '1rem',
            }}
            alignItems="center"
          >
            <Flex.Container style={{ marginRight: '1rem' }}>
              <Button $size="xs" onClick={handleFirst} style={{ marginRight: '1rem' }}>
                <Icon.ArrowsLeft color={Colors.white} size="lg" />
              </Button>
              <Button $size="xs" onClick={handlePrev} style={{ marginRight: '1rem' }}>
                <Icon.ArrowLeft color={Colors.white} size="lg" />
              </Button>
              <Text.Span style={{ whiteSpace: 'nowrap' }}>
                from {offset} to {getNextOffset()} of {totalElements}
              </Text.Span>
              <Button $size="xs" onClick={handleNext} style={{ marginLeft: '1rem' }}>
                <Icon.ArrowRight color={Colors.white} size="lg" />
              </Button>
              <Button $size="xs" onClick={handleLast} style={{ marginLeft: '1rem' }}>
                <Icon.ArrowsRight color={Colors.white} size="lg" />
              </Button>
            </Flex.Container>
            {/* <Text.Strong style={{ marginRight: '1rem' }}>Exchange:</Text.Strong> */}
            <Input.Select
              defaultValue={itemsPerPage}
              style={{ width: 'auto', marginRight: '1rem' }}
              onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                const { value } = evt.target;
                setItemsPerPage(stringToNumber(value));
              }}
            >
              {ITEMS_PER_PAGE.map((value, index) => {
                if (index + 1 < ITEMS_PER_PAGE.length) {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                }

                return (
                  <option key="all" value={value}>
                    All
                  </option>
                );
              })}
            </Input.Select>
          </Flex.Container>
        )}
        {exportable && (
          <Flex.Container
            style={{
              marginLeft: mediaQueryMatches ? '1rem' : '0',
              marginTop: mediaQueryMatches ? '0' : '1rem',
            }}
            alignItems="center"
          >
            <Button $size="sm" onClick={handlePrint} style={{ marginRight: '1rem' }}>
              <Text.Span style={{ marginRight: '1rem' }}>Export</Text.Span>
              <Icon.Print color={Colors.white} size="lg" />
            </Button>
          </Flex.Container>
        )}
      </Flex.Container>
      <Table.Container ref={tableRef} backgroundColor={Colors.notSoBlack}>
        <Table.Header>
          <Table.THR>
            {showIdColumn && <Table.TH style={{ textAlign: 'left' }}>#</Table.TH>}
            {properties
              .filter((p) => isPropertyVisible(p))
              .map((p) => (
                <Table.TH key={p.label} style={{ textAlign: p.align || DEFAULT_ALIGN }}>
                  {p.label}
                </Table.TH>
              ))}
          </Table.THR>
        </Table.Header>
        <Table.Body>
          {filteredElements
            .slice(offset, (itemsPerPage === 0 ? totalElements : itemsPerPage) + offset)
            .map((element) => (
              <Table.TR
                key={element[uniqueKeyName]}
                className={onRowSelected ? 'clickable' : ''}
                onClick={onRowSelected ? () => onRowSelected(element) : undefined}
              >
                {showIdColumn && (
                  <Table.TD style={{ textAlign: 'left' }}>{element[uniqueKeyName]}</Table.TD>
                )}
                {properties
                  .filter((p) => isPropertyVisible(p, element))
                  .map((p) => (
                    <DynamicTableTD
                      key={`${element[uniqueKeyName]}_${p.label}`}
                      prop={p}
                      element={element}
                      align={p.align || DEFAULT_ALIGN}
                    />
                  ))}
              </Table.TR>
            ))}
        </Table.Body>
      </Table.Container>
    </>
  );
};

export default styled(DynamicTable)<DynamicTableProps>`
  @media print {
    @page {
      size: landscape;
    }
  }
`;
