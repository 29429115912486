import {
  asClass,
  createContainer,
  InjectionMode,
  // asValue,
  // asFunction,
} from 'awilix';

import { Cradle } from '@/di/Cradle';
import {
  AuthBloc,
  AuthHTTPRepository,
  EditUserPasswordUseCase,
  GetAuthUseCase,
  LoginUseCase,
  LogoutUseCase,
} from '@/modules/auth';
import {
  GetAllQueuesStatusUseCase,
  JobBloc,
  JobHTTPRepository,
} from '@/modules/job';
import { HTTPService, LocalStorageRepository } from '@/modules/shared';
import {
  CloseExchangeOrderUseCase,
  DeleteOperationUseCase,
  EditExchangeUseCase,
  EditMethodParametersUseCase,
  EditPairUseCase,
  ExchangeHTTPRepository,
  GetAllExchangesUseCase,
  GetAllMethodParametersUseCase,
  GetAllOperationsUseCase,
  GetAllPairsUseCase,
  GetMultiScalpingV1SimulationUseCase,
  GetPairCandlesUseCase,
  GetPairUseCase,
  GetTradingSignalOperationsUseCase,
  GetTradingSignalsUseCase,
  GetTrailingStopV1SimulationUseCase,
  GetUserExchangeOrdersUseCase,
  GetUserExchangePositionsUseCase,
  ImportExchangeOrdersUseCase,
  ImportExchangeOrderUseCase,
  MethodParametersHTTPRepository,
  OperationHTTPRepository,
  PairHTTPRepository,
  StopTraderUseCase,
  TraderBloc,
  TradingSignalHTTPRepository,
} from '@/modules/trader';
import {
  CreateUserUseCase,
  DeleteUserUseCase,
  EditUserLeverageUseCase,
  EditUserMethodUseCase,
  GetUsersUseCase,
  GetUserUseCase,
  UserBloc,
  UserHTTPRepository,
} from '@/modules/user';

// Create the container
export const container = createContainer<Cradle>({
  injectionMode: InjectionMode.PROXY,
});

// There are 3 lifetime types available.

// Lifetime.TRANSIENT: This is the default. The registration is resolved every time it is needed. This means if you resolve a class more than once, you will get back a new instance every time.
// Lifetime.SCOPED: The registration is scoped to the container - that means that the resolved value will be reused when resolved from the same scope (or a child scope).
// Lifetime.SINGLETON: The registration is always reused no matter what - that means that the resolved value is cached in the root container.

// Inject the dependencies
container.register({
  // config
  // config must be registered at the APP Startup since it is environment dependant
  // BLoCs
  authBloc: asClass(AuthBloc).singleton(),
  userBloc: asClass(UserBloc).singleton(),
  traderBloc: asClass(TraderBloc).singleton(),
  jobBloc: asClass(JobBloc).singleton(),
  // Services
  httpService: asClass(HTTPService).singleton(),
  // Repos
  authRepository: asClass(AuthHTTPRepository).singleton(),
  userRepository: asClass(UserHTTPRepository).singleton(),
  operationRepository: asClass(OperationHTTPRepository).singleton(),
  exchangeRepository: asClass(ExchangeHTTPRepository).singleton(),
  pairRepository: asClass(PairHTTPRepository).singleton(),
  tradingSignalRepository: asClass(TradingSignalHTTPRepository).singleton(),
  storageRepository: asClass(LocalStorageRepository).singleton(),
  jobRepository: asClass(JobHTTPRepository).singleton(),
  methodParametersRepository: asClass(
    MethodParametersHTTPRepository,
  ).singleton(),
  // useCases
  loginUseCase: asClass(LoginUseCase).transient(),
  logoutUseCase: asClass(LogoutUseCase).transient(),
  getAuthUseCase: asClass(GetAuthUseCase).transient(),
  editUserPasswordUseCase: asClass(EditUserPasswordUseCase).transient(),
  getUserUseCase: asClass(GetUserUseCase).transient(),
  getUsersUseCase: asClass(GetUsersUseCase).transient(),
  deleteUserUseCase: asClass(DeleteUserUseCase).transient(),
  createUserUseCase: asClass(CreateUserUseCase).transient(),
  editUserLeverageUseCase: asClass(EditUserLeverageUseCase).transient(),
  editUserMethodUseCase: asClass(EditUserMethodUseCase).transient(),
  deleteOperationUseCase: asClass(DeleteOperationUseCase).transient(),
  getAllOperationsUseCase: asClass(GetAllOperationsUseCase).transient(),
  getTradingSignalOperationsUseCase: asClass(
    GetTradingSignalOperationsUseCase,
  ).transient(),
  getUserExchangeOrdersUseCase: asClass(
    GetUserExchangeOrdersUseCase,
  ).transient(),
  getUserExchangePositionsUseCase: asClass(
    GetUserExchangePositionsUseCase,
  ).transient(),
  closeExchangeOrderUseCase: asClass(CloseExchangeOrderUseCase).transient(),
  importExchangeOrderUseCase: asClass(ImportExchangeOrderUseCase).transient(),
  importExchangeOrdersUseCase: asClass(ImportExchangeOrdersUseCase).transient(),
  getTradingSignalsUseCase: asClass(GetTradingSignalsUseCase).transient(),
  getAllExchangesUseCase: asClass(GetAllExchangesUseCase).transient(),
  editExchangeUseCase: asClass(EditExchangeUseCase).transient(),
  getAllPairsUseCase: asClass(GetAllPairsUseCase).transient(),
  editPairUseCase: asClass(EditPairUseCase).transient(),
  getPairUseCase: asClass(GetPairUseCase).transient(),
  getPairCandlesUseCase: asClass(GetPairCandlesUseCase).transient(),
  getAllMethodParametersUseCase: asClass(
    GetAllMethodParametersUseCase,
  ).transient(),
  editMethodParametersUseCase: asClass(EditMethodParametersUseCase).transient(),
  getMultiScalpingV1SimulationUseCase: asClass(
    GetMultiScalpingV1SimulationUseCase,
  ).transient(),
  getTrailingStopV1SimulationUseCase: asClass(
    GetTrailingStopV1SimulationUseCase,
  ).transient(),
  stopTraderUseCase: asClass(StopTraderUseCase).transient(),
  getAllQueuesStatusUseCase: asClass(GetAllQueuesStatusUseCase).transient(),
});
