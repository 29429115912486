import { Action, TradingSignalDTO } from '@robotrader/common-types';
import { DataError, Either } from '@robotrader/core-lib';

import { AppConfig } from '@/core/config';
import { HTTPService } from '@/modules/shared';
import {
  GetAllTradingSignalsProps,
  MultiScalperV1UseCaseProps,
  TradingSignal,
  TradingSignalRepository,
  TrailingStopV1UseCaseProps,
} from '@/modules/trader/domain';

import { GetTradingSignalsResponse } from './GetTradingSignalsResponse';

interface TradingSignalHTTPRepositoryProps {
  httpService: HTTPService;
  config: AppConfig;
}

export class TradingSignalHTTPRepository implements TradingSignalRepository {
  private http: HTTPService;
  private config: AppConfig;

  constructor({ httpService, config }: TradingSignalHTTPRepositoryProps) {
    this.http = httpService;
    this.config = config;
  }

  async simulateTrailingStopV1(
    props: TrailingStopV1UseCaseProps,
  ): Promise<Either<DataError, TradingSignal[]>> {
    const {
      symbol,
      initialDirection,
      exchange,
      dateFrom,
      dateTo,
      trailingDelta,
      maxIterations,
      detectDayChange,
      goalMargin,
    } = props;
    try {
      let {
        data: { data: tradingSignalsDto },
      } = await this.http.get<GetTradingSignalsResponse>(
        `${this.config.apiUrl}/admin/trader/trailing-stop-v1`,
        {
          params: {
            symbol,
            initialDirection,
            exchange,
            from: dateFrom,
            to: dateTo,
            trailingDelta,
            maxIterations,
            detectDayChange,
            goalMargin,
            simulated: true,
          },
        },
      );

      tradingSignalsDto = tradingSignalsDto.sort((ts1, ts2) =>
        ts1.timestamp > ts2.timestamp ? 1 : -1,
      );

      let accum = 0;
      const tradingSignals: TradingSignal[] = tradingSignalsDto
        .map((tsDto: TradingSignalDTO) => {
          const fee = tsDto.fee || 0;

          if (tsDto.outcome && tsDto.action === Action.EXIT) {
            accum += tsDto.outcome;
          }

          if (tsDto.fee) {
            accum -= fee;
          }

          return TradingSignal.create({
            ...tsDto,
            accum,
            fee: fee ? -fee : undefined,
          });
        })
        .reverse();

      return Either.right(tradingSignals);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async getAll(
    props?: GetAllTradingSignalsProps,
  ): Promise<Either<DataError, TradingSignal[]>> {
    const {
      exchange,
      includeCheckpoints,
      timeFrom,
      timeTo,
      method,
      pair,
      action,
    } = props || {};
    const params = { exchange, pair };
    try {
      let {
        data: { data: tradingSignalsDto },
      } = await this.http.get<GetTradingSignalsResponse>(
        `${this.config.apiUrl}/admin/trader/trading-signals`,
        { params },
      );

      tradingSignalsDto = tradingSignalsDto
        .filter((tsDto) =>
          includeCheckpoints ? true : tsDto.checkpoint === false,
        )
        .filter((ts) => (timeFrom ? ts.timestamp >= timeFrom : true))
        .filter((ts) => (timeTo ? ts.timestamp <= timeTo : true))
        .filter((ts) => (method ? ts.method === method : true))
        .filter((ts) => (action ? ts.action === action : true))
        .sort((ts1, ts2) => {
          if (ts1.timestamp === ts2.timestamp) {
            if (ts1.createdAt === ts2.createdAt) {
              return ts1.action === Action.EXIT ? -1 : 1;
            }

            return ts1.createdAt > ts2.createdAt ? 1 : -1;
          }

          return ts1.timestamp > ts2.timestamp ? 1 : -1;
        });

      let accum = 0;
      const tradingSignals: TradingSignal[] = tradingSignalsDto
        .map((tsDto: TradingSignalDTO) => {
          if (tsDto.outcome && tsDto.action === Action.EXIT) {
            accum += tsDto.outcome;
          }

          return TradingSignal.create({ ...tsDto, accum });
        })
        .reverse();

      return Either.right(tradingSignals);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async stopTrader(): Promise<Either<DataError, void>> {
    try {
      await this.http.post(`${this.config.apiUrl}/admin/trader/stop`);

      return Either.right(undefined as void);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }

  async simulateMultiScalpingV1(
    props: MultiScalperV1UseCaseProps,
  ): Promise<Either<DataError, TradingSignal[]>> {
    const {
      exchange,
      dateFrom,
      dateTo,
      targetDistanceEma21,
      targetDistanceEma8,
      ema8MagicDelta,
      rsiDeltaShape,
      rsiPeriod,
      rsiHigh,
      rsiLow,
      rsiHighEdge,
      rsiLowEdge,
      dropRate,
      stopMargin,
      goalMargin,
      acceptedCandleVolatility,
      fee,
    } = props;
    try {
      let {
        data: { data: tradingSignalsDto },
      } = await this.http.get<GetTradingSignalsResponse>(
        `${this.config.apiUrl}/admin/trader/multi-scalping-v1`,
        {
          params: {
            exchange,
            from: dateFrom,
            to: dateTo,
            targetDistanceEma21,
            targetDistanceEma8,
            ema8MagicDelta,
            rsiDeltaShape,
            rsiPeriod,
            rsiHigh,
            rsiLow,
            rsiHighEdge,
            rsiLowEdge,
            dropRate,
            stopMargin,
            goalMargin,
            acceptedCandleVolatility,
            simulated: true,
          },
        },
      );

      tradingSignalsDto = tradingSignalsDto.sort((ts1, ts2) =>
        ts1.timestamp > ts2.timestamp ? 1 : -1,
      );

      let accum = 0;
      const tradingSignals: TradingSignal[] = tradingSignalsDto
        .map((tsDto: TradingSignalDTO) => {
          if (tsDto.outcome && tsDto.action === Action.EXIT) {
            accum += tsDto.outcome;
          }

          if (fee) {
            accum -= fee;
          }

          return TradingSignal.create({
            ...tsDto,
            accum,
            fee: fee ? -fee : undefined,
          });
        })
        .reverse();

      return Either.right(tradingSignals);
    } catch (error) {
      return Either.left({ kind: 'Unauthorized', message: error as Error });
    }
  }
}
